import React from "react";
import lax from "lax.js";
import { Link } from "react-router-dom";
import LaxButton from "../Shared/LaxButton";
import Registerform from "../pages/Registerform";
import ReactPlayer from "react-player";

class Video extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section className="about-area ptb-120 bg-image" id="video">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactPlayer
                  light="/thumb.jpg"
                  playing
                  controls
                  url="/video.mp4"
                />

              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactPlayer
                  light="/vinay.jpg"
                  playing
                  controls
                  url="/video2.mp4"
                />

              </div>
            </div>
          </div>
        </div>
        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </section>
    );
  }
}

export default Video;
