import React from "react";
import lax from "lax.js";
import { Link } from "react-router-dom";
import LaxButton from "../Shared/LaxButton";
import Registerform from "../pages/Registerform";

class About extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section className="about-area ptb-120 bg-image" id="about">
        <div className="container">
        {/* <div className="section-title">
                        <h2>Register Now</h2>

                     
                    </div> */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab">
                                <ul className="tabs active">
                                    <li
                                        onClick={(e) => this.openTabSection(e, 'tab4')}
                                        className="current"
                                        style={{
                                          backgroundColor:"#3D72C1"
                                        }}
                                    >
                                        <Link to="#">
                                           Register Now
                                            <span>Please enter your details</span>
                                        </Link>
                                    </li>

                                    

                                    
                                </ul>

                                <div className="tab_content" style={{backgroundColor:"#fff"}}>
                                    {/* <div id="tab4" className="tabs_item"> */}
                                    <Registerform/>
                                    {/* </div> */}

                                   
                                </div>
                            </div>
                        </div>

                     
                    </div>



          
        </div>
        <div className="shape1"><img src={require("../../assets/images/shapes/1.png")} alt="shape1" /></div>
                <div className="shape2 rotateme"><img src={require("../../assets/images/shapes/2.png")} alt="shape2" /></div>
                <div className="shape3 rotateme"><img src={require("../../assets/images/shapes/3.png")} alt="shape3" /></div>
                <div className="shape4"><img src={require("../../assets/images/shapes/4.png")} alt="shape4" /></div>
           
      </section>
    );
  }
}

export default About;
