// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/database'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config = {
  apiKey: "AIzaSyDexw8jQNCJVbBguEbVmBorqpfUbj3WXto",
  authDomain: "warriorclub2023.firebaseapp.com",
  databaseURL: "https://warriorclub2023-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "warriorclub2023",
  storageBucket: "warriorclub2023.appspot.com",
  messagingSenderId: "62063488251",
  appId: "1:62063488251:web:8993cff67639097608545e",
  measurementId: "G-J3J1713X21"
};
firebase.initializeApp(config)
firebase.analytics();