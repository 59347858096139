import React from "react";
import ReactPlayer from "react-player";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
class MainBanner extends React.Component {
  state = {
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
    modal:true,
    isOpen: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  makeTimer = () => {
    let endTime = new Date("June 16, 2022 17:00:00 PDT");
    let endTimeParse = Date.parse(endTime) / 1000;
    let now = new Date();
    let nowParse = Date.parse(now) / 1000;
    let timeLeft = endTimeParse - nowParse;
    let days = Math.floor(timeLeft / 86400);
    let hours = Math.floor((timeLeft - days * 86400) / 3600);
    let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
    let seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    );
    if (hours < "10") {
      hours = "0" + hours;
    }
    if (minutes < "10") {
      minutes = "0" + minutes;
    }
    if (seconds < "10") {
      seconds = "0" + seconds;
    }
    this.setState({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  componentDidMount() {
    // this.myInterval = setInterval(() => {
    //   this.makeTimer();
    // }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    return (
      <React.Fragment>
          <Modal className={`modal-dialog-centered modal-lg`} isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Join Us & discover the Warrior within</ModalHeader>
          <ModalBody style={{ overflow: "auto", height: "100%" }}>
       <div style={{display:"flex",justifyContent:"center"}}>
          <ReactPlayer
                  light="/thumb.jpg"
                  playing
                  controls
                  url="/video.mp4"
                />
</div>
          </ModalBody>
          </Modal>
        {/* <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="cRXm1p-CNyk"
          onClose={() => this.setState({ isOpen: false })}
        /> */}

        <div className="">
          <img src="/banner2.jpg" style={{marginTop:60}}/>
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  {/* <img
                    src={require("../../assets/images/mainlogo.png")}
                    width={isMobile?"100%":"70%"}
                    style={{
                    
                    }}
                  /> */}
                  
                </div>
              </div>
            </div>
          </div>

          {/* <div className="event-countdown countdown1">
            <div id="timer">
              <div id="days">
                {this.state.days} <span>Days</span>
              </div>
              <div id="hours">
                {this.state.hours} <span>Hours</span>
              </div>
              <div id="minutes">
                {this.state.minutes} <span>Minutes</span>
              </div>
              <div id="seconds">
                {this.state.seconds} <span>Seconds</span>
              </div>
            </div>
          </div> */}

          {/* <div className="shape1">
            <img
              src={require("../../assets/images/shapes/1.png")}
              alt="shape1"
            />
          </div>

          <div className="shape2 rotateme">
            <img
              src={require("../../assets/images/shapes/2.png")}
              alt="shape2"
            />
          </div> */}

          {/* <div className="shape3 rotateme">
            <img
              src={require("../../assets/images/shapes/3.png")}
              alt="shape3"
            />
          </div> */}

          {/* <div className="shape4">
            <img
              src={require("../../assets/images/shapes/4.png")}
              alt="shape4"
            />
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default MainBanner;
