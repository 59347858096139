import React from "react";
import { Link } from "react-router-dom";

class WhyUs extends React.Component {
  render() {
    return (
      <>
        {/* <section className="about-area ptb-120 bg-image" id="whyus">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="about-" style={{ fontSize: 18 }}>
                <p style={{ fontSize: 15 }}>
                  Making money and staying relevant and ahead of your
                  competitors is what it’s all about. Partnering with Hitachi
                  Vantara brings together the best of both worlds. Leading
                  technology, rich incentives for greater profitability, with
                  collaboration and teamwork to help you succeed.
                </p>
                <p style={{ fontSize: 15 }}>
                  At Hitachi Vantara, we give our partners the front-row access
                  to emerging technologies like cloud, object storage, Internet
                  of Things (IoT) and much more.
                </p>

                <p style={{ fontSize: 15 }}>
                  Our offerings help customers adapt to the needs of workloads
                  of the future with a modernized edge-to-core-to-cloud
                  infrastructure that delivers agility, efficiency, and
                  resiliency. Take an integrated, strategic approach to
                  application modernization, data modernization and
                  infrastructure modernization. Work from this digital
                  foundation to innovate, evolve and deliver exceptional
                  experiences for our customers.
                </p>

                <p style={{ fontSize: 15 }}>
                  As our valued partner, you will have the chance to expand your
                  value by leveraging the Hitachi Vantara offerings.
                </p>
              </div>
            </div>
          </div>
          
        </section> */}
        <section className="why-choose-us" id="whyus1">
          <div className="row m-0">
            <div className="col-lg-3 col-sm-6 p-0" style={{position:"relative",backgroundColor:"rgba(0, 0, 0,0.5)"}} >
             <img src="/extra/network.jpg" style={{position:"absolute",zIndex:-1,height:250}}/>
              <div className="single-box">
              
                      {/* <div className="icon">
                        <img src="/extra/network.jpg" style={{borderRadius:10,marginBottom:10}} width={150}/>
                      </div> */}
                      <div style={{fontSize:19,color:"#fff",zIndex:12}}>Networking and Knowledge Sharing</div>
                      <p  style={{fontSize:14,color:"#fff"}}>
                      Connect with like-minded professionals and share insights in a relaxed, enjoyable, and collaborative atmosphere.</p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                   
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 p-0"  style={{position:"relative",backgroundColor:"rgba(0, 0, 0,0.5)"}}>
            <img src="/extra/stu.jpg" style={{position:"absolute",zIndex:-1,height:250}}/>

              <div className="single-box">
               
                      {/* <div className="icon">
                      <img src="/extra/stu.jpg" style={{borderRadius:10,marginBottom:10}} width={150}/>

                      </div> */}
                      <div style={{fontSize:19,color:"#fff"}}>Stunning Kerala Backwaters</div>
                      <p  style={{fontSize:14,color:"#fff"}}>
                      Immerse yourself in the natural beauty of Vembanad Lake. Unwind and soak in the tranquillity of this picturesque region.</p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                  
              </div>
            </div>

         

            <div className="col-lg-3 col-sm-6 p-0"  style={{position:"relative",backgroundColor:"rgba(0, 0, 0,0.5)"}}>
            <img src="/extra/cultural.jpg" style={{position:"absolute",zIndex:-1,height:250}}/>

              <div className="single-box">
                
                      {/* <div className="icon">
                      <img src="/extra/cultural.jpg" style={{borderRadius:10,marginBottom:10}} width={150}/>

                      </div> */}
                      <div style={{fontSize:19,color:"#fff"}}>Cultural Extravaganza</div>
                      <p style={{fontSize:14,color:"#fff"}}>
                      Experience the vibrant and diverse culture of Kerala through traditional dance performances, music, and a taste of local cuisine.                      </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                  
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 p-0"   style={{position:"relative",backgroundColor:"rgba(0, 0, 0,0.5)"}}>
            <img src="/extra/rec.jpg" style={{position:"absolute",zIndex:-1,height:250}}/>

              <div className="single-box">
               
                      {/* <div className="icon">
                      <img src="/extra/rec.jpg" style={{borderRadius:10,marginBottom:10}} width={150}/>

                      </div> */}
                      <div style={{fontSize:19,color:"#fff"}}>Recognition and Awards</div>
                      <p  style={{fontSize:14,color:"#fff"}}>
                      Be honoured for your exceptional contributions. The Partner Event is where we acknowledge your outstanding achievements and commitment. </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                  
              </div>
            </div>
          </div>

          {/* <ul className="slideshow">
            <li>
              <span></span>
            </li>
          
           
          </ul> */}
        </section>
      </>
    );
  }
}

export default WhyUs;
