import React from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { history } from "../../history";
import firebase from "firebase";
import { Spinner } from "reactstrap";
import { isMobile, withOrientationChange } from "react-device-detect";
import Select from "react-select";
import { Form, FormGroup , Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,} from "reactstrap";
import DatePicker from "react-datepicker";
import Moment from "moment";

// import 'react-datetime-picker/dist/DateTimePicker.css';
import "react-datepicker/dist/react-datepicker.css";

// import 'react-calendar/dist/Calendar.css';
// import 'react-clock/dist/Clock.css';
class Registerform extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    city: "",
    position: "",
    checked2:false,
    modal:false,
    company: "",
    phone: "",
    photo: null,
    photo2: null,
    isloading: false,
    successAlert: false,
    errorAlert: false,
    errormsg: "",
    relation: null,
    checked: false,
    dietrist:"",
    category: null,
    startDate: new Date("2023/07/12"),
    checkoutDate: new Date("2023/07/14"),
    tshirt: null,
  };
  // const [value, onChange] = useState(new Date());
  onChange = (data) => {
    this.setState({ startDate: data });
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
    // history.push("/login");
  };
  handleAlert2 = (state, value) => {
    this.setState({ [state]: value });
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.onward === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please enter Onward Journey City.",
      });
    } else if (this.state.return === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please enter Return Journey City.",
      });
    } else if (!this.state.checked) {
      this.setState({
        errorAlert: true,
        errormsg:
          "Please select that deduction of tax as per 194R a TDS checkbox",
      });
    } else if (this.state.photo === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please add Aadhar Card.",
      });
    } else if (this.state.photo2 === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please add Pan Card.",
      });
    } else  if (this.state.tshirt === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select T-Shirt Size.",
      });
    }
    // else if (this.state.email.endsWith("@framez.sg")&&this.state.email.endsWith("@netradyne.com")) {
    //   this.setState({
    //     errorAlert: true,
    //     errormsg: "Please use your Company Email Id.",
    //   });
    // }
    else {
      this.setState({
        isloading: true,
      });

      var fileext = this.state.filetype.includes("png") ? ".png" : ".jpg";
      firebase
        .storage()
        .ref("files/" + new Date().getTime() + fileext)
        .putString(
          this.state.photo.replace(/^data:image\/[a-z]+;base64,/, ""),
          "base64",
          { contentType: this.state.filetype }
        )
        // .put(this.state.photo)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at1", downloadURL);

            var fileext2 = this.state.filetype2.includes("png")
              ? ".png"
              : ".jpg";
            firebase
              .storage()
              .ref("files/" + new Date().getTime() + fileext2)
              .putString(
                this.state.photo2.replace(/^data:image\/[a-z]+;base64,/, ""),
                "base64",
                { contentType: this.state.filetype2 }
              )
              // .put(this.state.photo)
              .then((snapshot2) => {
                snapshot2.ref.getDownloadURL().then((downloadURL2) => {
                  console.log("File available at2", downloadURL2);
                  var registeruser = firebase
                    .app()
                    .functions("asia-east2")
                    .httpsCallable("createuser");
                  registeruser({
                    title: this.state.title,
                    firstname: this.state.name,
                    lastname: this.state.lastname,
                    aadhar: downloadURL,
                    pancard: downloadURL2,
                    email: this.state.email,
                    phone: this.state.phone,
                    organisation: this.state.organisation,
                    designation: this.state.designation,
                    meal: this.state.meal,
                    tshirtother: this.state.tshirtother,
                    tshirt:this.state.tshirt.value,
                    dietrist: this.state.dietrist?this.state.dietrist:"",
                    onward: this.state.onward.value,
                    return: this.state.return.value,
                    onwardother: this.state.onwardother,
                   
                    returnother: this.state.returnother,
                    // phone: this.state.phone,
                    // email: this.state.email,
                    time: new Date().toString(),
                    // plusone: this.state.plusone,
                    // compfirstname: this.state.compfirstname?this.state.compfirstname:"",
                    // complastname: this.state.complastname?this.state.complastname:"",
                    // relation: this.state.relation ? this.state.relation.value : "",
                    // password: this.state.password
                  }).then((data) => {
                    console.log(data);
                    console.log(data.data.err);
                    console.log(JSON.stringify(data));
                    if (data.data.err == 0) {
                      console.log("REgister done");
                      this.setState({
                        successAlert: true,
                        isloading: false,
                        title: this.state.title,
                        name: "",
                        lastname: "",
                        photo: null,
                        photo2: null,
                        title: null,
                        email: "",
                        phone: "",
                        organisation: "",
                        checked: false,
                        designation: "",
                        meal: null,
                        dietrist: "",
                        onward: null,
                        return: null,
                        onwardother: "",
                        returnother: "",
                      });
                    } else if (data.data.err === 1) {
                      console.log("Already registered");
                      this.setState({
                        errorAlert: true,
                        errormsg: "Already Registered",
                        isloading: false,
                      });
                    } else {
                      console.log("There was an error");
                      this.setState({
                        errorAlert: true,
                        errormsg: "There was an error",
                        isloading: false,
                      });
                    }
                  });
                });
              });
          });
        });
    }
  };
  render() {
    return (
      <section className="signup-area">
      
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Confirmation!!!</ModalHeader>
          <ModalBody>
          <form onSubmit={this.onSubmit}>
          <div class="form-check" style={{ marginTop: 10 }}>
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  onChange={(e) => {
                    this.setState({
                      checked2: !this.state.checked2,
                    });
                  }}
                  id="flexCheckChecked2"
                  checked={this.state.checked2}
                />
                <label class="form-check-label" for="flexCheckChecked2">
                I accept that all the given information is best to my knowledge and aware that the same will be used for all the necessary bookings.
                </label>
              </div>
              <div style={{display:"flex",justifyContent:"center"}}>
              <button
                disabled={this.state.isloading}
                type="submit"
                style={{ zIndex: 0 }}
                className="btn btn-primary"
              >
                {this.state.isloading ? <Spinner color="red" /> : "Register"}
              </button>
              </div>
            </form>

          </ModalBody>
          </Modal>
        <SweetAlert
          success
          title="Success"
          style={{ zIndex: 10000 }}
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            Thank you for registering. Please check your mail.
          </p>
        </SweetAlert>

        <SweetAlert
          title="Error"
          show={this.state.errorAlert}
          onConfirm={() => this.handleAlert2("errorAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            {this.state.errormsg}
          </p>
        </SweetAlert>
        <div>
        

          <div>
            <h1 style={{textAlign:"center"}}>Registrations are closed Now!!</h1>
          </div>
          <div className="signup-form" style={{display:"none"}}>
            {/* <h3>Create your Account</h3> */}
            {/* <h3>Registrations are now closed for the summit</h3> */}
            <form onSubmit={(e)=>{
              e.preventDefault();
              if (this.state.onward === null) {
                this.setState({
                  errorAlert: true,
                  errormsg: "Please enter Onward Journey City.",
                });
              } else if (this.state.return === null) {
                this.setState({
                  errorAlert: true,
                  errormsg: "Please enter Return Journey City.",
                });
              } else if (!this.state.checked) {
                this.setState({
                  errorAlert: true,
                  errormsg:
                    "Please select that deduction of tax as per 194R a TDS checkbox",
                });
              } else if (this.state.photo === null) {
                this.setState({
                  errorAlert: true,
                  errormsg: "Please add Aadhar Card.",
                });
              } else if (this.state.photo2 === null) {
                this.setState({
                  errorAlert: true,
                  errormsg: "Please add Pan Card.",
                });
              }
              // else if (this.state.email.endsWith("@framez.sg")&&this.state.email.endsWith("@netradyne.com")) {
              //   this.setState({
              //     errorAlert: true,
              //     errormsg: "Please use your Company Email Id.",
              //   });
              // }
              else {
              this.setState({
                modal:true
              })
            }
            }}>
              <div className="form-group">
                <label style={{ marginRight: 20 }}>Title </label>
                <div class="form-check form-check-inline">
                  <input
                    required
                    onChange={(e) => this.setState({ title: "Mr." })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    checked={this.state.title === "Mr."}
                    id="inlineRadio1"
                    value="option1"
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Mr.
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ title: "Ms." })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    checked={this.state.title === "Ms."}
                    id="inlineRadio2"
                    value="option2"
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Ms.
                  </label>
                </div>
              </div>
              <p style={{textAlign:"left",fontSize:12,fontStyle:"italic",color:"grey"}}>*Please fill name as per Aadhar card</p>
              <div className="form-group">
                {/* <label>Name</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={this.state.lastname}
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                />
              </div>
            
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Contact Number"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="email"
                  className="form-control"
                  placeholder="Official E-mail id"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Organisation Name"
                  value={this.state.organisation}
                  onChange={(e) =>
                    this.setState({ organisation: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Designation"
                  value={this.state.designation}
                  onChange={(e) =>
                    this.setState({ designation: e.target.value })
                  }
                />
              </div>

              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="City of departure"
                  value={this.state.citydepart}
                  onChange={(e) =>
                    this.setState({ citydepart: e.target.value })
                  }
                />
              </div> */}
              <div className="form-group">
                <label style={{ marginRight: 20 }}>Meal preference </label>
                <div class="form-check form-check-inline">
                  <input
                    required
                    onChange={(e) => this.setState({ meal: "Vegetarian" })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    checked={this.state.meal === "Vegetarian"}
                    id="inlineRadio11"
                    value="option11"
                  />
                  <label class="form-check-label" for="inlineRadio11">
                    Vegetarian
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ meal: "Non-Vegetarian" })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    checked={this.state.meal === "Non-Vegetarian"}
                    id="inlineRadio22"
                    value="option22"
                  />
                  <label class="form-check-label" for="inlineRadio22">
                    Non-Vegetarian
                  </label>
                </div>
              </div>
              <div className="form-group">
              <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.tshirt}
                        onChange={(a) => {
                          this.setState({
                            tshirt: a,
                          });
                        }}
                        placeholder="T-shirt Size"
                        options={[
                          {
                            value: "S - 36",
                            label: "S - 36",
                          },
                          {
                            value: "M - 38",
                            label: "M - 38",
                          },
                          {
                            value: "L - 40",
                            label: "L - 40",
                          },
                          {
                            value: "XL - 42",
                            label: "XL - 42",
                          },
                          {
                            value: "XXL - 44",
                            label: "XXL - 44",
                          },
                          {
                            value: "3XL - 46",
                            label: "3XL - 46",
                          },
                          {
                            value: "Others",
                            label: "Others",
                          },
                        ]}
                      />
              </div>
              {this.state.tshirt && this.state.tshirt.value === "Others" ? (
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Please Enter T-shirt size"
                    value={this.state.tshirtother}
                    onChange={(e) =>
                      this.setState({ tshirtother: e.target.value })
                    }
                  />
                </div>
              ) : null}
              <div className="form-group">
                <input
                  
                  type="text"
                  className="form-control"
                  placeholder="Any dietary restriction"
                  value={this.state.dietrist}
                  onChange={(e) => this.setState({ dietrist: e.target.value })}
                />
              </div>
              <h3>Air ticket itinerary</h3>
              <p>
                The above provided options are considered only on basis of the
                availability, if not available our team representative will get
                in touch with you.
              </p>
              <div className="form-group">
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  value={this.state.onward}
                  onChange={(a) => {
                    this.setState({
                      onward: a,
                    });
                  }}
                  placeholder="Onward Journey"
                  options={[
                    {
                      value: "Bengaluru",
                      label: "Bengaluru | 6E 113 | 9:00 - 10:05 | 1H 5M",
                    },
                    {
                      value: "Mumbai",
                      label: "Mumbai | 6E 6701 | 8:55 - 10:50 | 1H 55M",
                    },
                    {
                      value: "Chennai",
                      label: "Chennai | 6E 5394 | 8:10 - 9:25 | 1H 15M",
                    },
                    {
                      value: "Delhi",
                      label: "Delhi | UK 883 | 6:00 - 9:05 | 3H 5M",
                    },
                    {
                      value: "Hyderabad",
                      label: "Hyderabad |  6E 637 | 7:00 - 8:35 | 1H 35M",
                    },
                    {
                      value: "Pune",
                      label: "Pune | 6E 352 & 6E 6707 | 6:25 - 11:20 | 4H 55M",
                    },
                    {
                      value: "Vadodara",
                      label:
                        "Vadodara | AI 670 & AI 693 | 7:00 - 14:05 | 7H 5M",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </div>
              {this.state.onward && this.state.onward.value === "Other" ? (
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Please Enter Onward Journey City"
                    value={this.state.onwardother}
                    onChange={(e) =>
                      this.setState({ onwardother: e.target.value })
                    }
                  />
                </div>
              ) : null}
              <div className="form-group">
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  value={this.state.return}
                  onChange={(a) => {
                    this.setState({
                      return: a,
                    });
                  }}
                  placeholder="Return Journey"
                  options={[
                    {
                      value: "Bengaluru",
                      label: "Bengaluru | 6E 196 | 11:15 - 12:25 | 1H 10M",
                    },
                    {
                      value: "Mumbai",
                      label: "Mumbai | UK 516 | 10:10 - 12:30 | 2H 20M",
                    },
                    {
                      value: "Chennai",
                      label: "Chennai | 6E 571 | 12:45 - 13:55 | 1H 10M",
                    },
                    {
                      value: "Delhi",
                      label: "Delhi | 6E 2382 | 16:00 - 19:20 | 3H 20M",
                    },
                    {
                      value: "Hyderabad",
                      label: "Hyderabad |  6E 304 | 11:50 - 13:10 | 1H 20M",
                    },
                    {
                      value: "Pune",
                      label: "Pune | 6E 571 & 6E 183 | 12:45 - 17:20 | 4H 35M",
                    },
                    {
                      value: "Vadodara",
                      label:
                        "Vadodara | 6E 196 & 6E 807 | 11:15 - 17:50 | 6H 35M",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
                {/* <Label>Company size </Label> */}
              </div>
              {this.state.return && this.state.return.value === "Other" ? (
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Please Enter Return Journey City"
                    value={this.state.returnother}
                    onChange={(e) =>
                      this.setState({ returnother: e.target.value })
                    }
                  />
                </div>
              ) : null}
              {/* <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                        }}
                      > */}
              {this.state.photo ? (
                <div style={{ padding: 10 }}>
                  <img width={"100%"} src={this.state.photo} />
                </div>
              ) : null}
              <div style={{ display: "flex", flex: 1 }}>
                <p style={{ textAlign: "justify", flex: 2 }}>
                  For ticketing & hotel reservations - Please upload your
                  Aadhaar card{" "}
                </p>
                <input
                  id="myInput"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    var file = event.target.files[0];
                    console.log(file);
                    if (file.size > 2097152) {
                      alert("Please select file size less than 2mb");
                    } else {
                      console.log(file.type);
                      if (
                        !(
                          file.type.includes("png") ||
                          file.type.includes("jpg") ||
                          file.type.includes("jpeg")
                        )
                      ) {
                        alert("Please select only JPG or PNG file");
                      } else {
                        var imagebase64 = "";
                        var reader = new FileReader();
                        reader.onloadend = () => {
                          imagebase64 = reader.result;
                          this.setState({
                            photo: imagebase64,
                            filetype: file.type,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }
                    // this.setState({file});
                  }}
                />
                <div  style={{
                   
                    flex: 1,
                    marginTop:10
                   
                  }}>
                <div
                  className="registerbutton2"
                  disabled={this.state.isloading}
                  color="warning"
                  style={{
                    margin: 10,
                    // flex: 1,
                    paddingTop:30,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: 30,
                  }}
                  type="submit"
                  onClick={() => {
                    this.upload.click();
                  }}
                >
                  Upload
                </div>
              </div>
              </div>
              {/* </div> */}
              {/* <div
                        style={{
                          flex: 1,
                        }}
                      > */}
              <div class="form-check" style={{ marginTop: 10 }}>
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  onChange={(e) => {
                    this.setState({
                      checked: !this.state.checked,
                    });
                  }}
                  id="flexCheckChecked"
                  checked={this.state.checked}
                />
                <label class="form-check-label" for="flexCheckChecked">
                  I accept that deduction of tax as per 194R a TDS at the rate
                  of 10% will be debited and recover from the individual /
                  entity via a debit note towards the tour cost.
                </label>
              </div>
              {this.state.photo2 ? (
                <div style={{ padding: 10 }}>
                  <img width={"100%"} src={this.state.photo2} />
                </div>
              ) : null}
              <div style={{ display: "flex", flex: 1 }}>
                {/* <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#000",
                              fontSize: 10,
                            }}
                          >
                            Back Page
                          </div> */}

                {/* <div className="form-group">
                  <input
                    required
                    type="checkbox"
                    className="form-control"
                    placeholder="Job Title"
                    value={this.state.position}
                    onChange={(e) =>
                      this.setState({ position: e.target.value })
                    }
                  />
                </div> */}
                {this.state.checked ? (
                  <>
                    <p style={{ textAlign: "justify", flex: 2 }}>
                      To support 194R please upload your Pan-card
                    </p>
                    <input
                      id="myInput"
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      ref={(ref) => (this.upload2 = ref)}
                      style={{ display: "none" }}
                      onChange={(event) => {
                        var file = event.target.files[0];
                        console.log(file);
                        if (file.size > 2097152) {
                          alert("Please select file size less than 2mb");
                        } else {
                          if (
                            !(
                              file.type.includes("png") ||
                              file.type.includes("jpg") ||
                              file.type.includes("jpeg")
                            )
                          ) {
                            alert("Please select only JPG or PNG file");
                          } else {
                            var imagebase64 = "";
                            var reader = new FileReader();
                            reader.onloadend = () => {
                              imagebase64 = reader.result;
                              this.setState({
                                photo2: imagebase64,
                                filetype2: file.type,
                              });
                            };
                          }
                          reader.readAsDataURL(file);
                        }
                        // this.setState({file});
                      }}
                    />
                    <div style={{flex:1}}>
                      <div
                        className="registerbutton3"
                        disabled={this.state.isloading}
                        // color="warning"
                        style={{
                          margin: 10,
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          marginTop: 20,
                          height: 30,
                        }}
                        type="submit"
                        onClick={() => {
                          this.upload2.click();
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  </>
                ) : null}
                {/* </div>
                      </div> */}
              </div>

              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Job Title"
                  value={this.state.position}
                  onChange={(e) => this.setState({ position: e.target.value })}
                />
              </div> */}

              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Companion First Name"
                  value={this.state.compfirstname}
                  onChange={(e) => this.setState({ compfirstname: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Companion Last Name"
                  value={this.state.complastname}
                  onChange={(e) => this.setState({ complastname: e.target.value })}
                />
              </div> */}
              {/* <p style={{ textAlign: "justify", marginBottom: 10 }}>
                Section 194R is towards Deduction of tax on benefit or
                perquisite in respect of business or profession - Travel scheme
                accounts for benefit or perquisite in case of person travelling
                in its individual capacity or it accounts for the benefit in
                case of an entity if the person travelling represents the
                entity. TDS at the rate of 10% will be debited and recovered
                from the individual / entity via a debit note of its share
                towards the tour cost.{" "}
              </p> */}

              <button
                disabled={this.state.isloading}
                type="submit"
                style={{ zIndex: 0 }}
                className="btn btn-primary"
              >
                {this.state.isloading ? <Spinner color="red" /> : "Register"}
              </button>

              {/* <p>
                  Already a registered user? <Link to="/login">Login!</Link>
                </p> */}
            </form>
          </div>
          {/* </div> */}
        </div>
      </section>
    );
  }
}

export default Registerform;
